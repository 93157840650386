import React, { useState, useEffect } from "react";
import Tasks from "../components/Tasks";
import Sidebar from "./Sidebar";
import Swal from 'sweetalert2'
import axios from "axios";

import authHeader from "../services/auth-header";
import auth_service from "../services/auth.service"

const getStatus = (id) => {
    const ticket = {
        1: 'New',
        2: 'Open',
        3: 'Pending',
        4: 'Waiting',
        5: 'Solved',
        6: 'Closed'
    }
    return ticket[id];
}

const getPriority = (id) => {
    const ticket =
    {
        1: 'Low',
        2: 'Medium',
        3: 'High',
        4: 'Urgent',
        5: 'Critical'
    }
    return ticket[id];
}

const getType = (id) => {
    const ticket =
    {
        1: "Incident",
        2: "Service request",
        3: "Question",
        4: "Problem",
        5: "Change request",
        6: "Major incident"
    }
    return ticket[id];
}

const dbData = () => {
    return new Promise((resolve, reject) => {
        const user = auth_service.getCurrentUser();
        //const params = { email: 'gjimenez@insideone.cloud' }
        const params = { email: user.email }
        const headers = { headers: authHeader() }
        axios.post(`https://us-central1-test-bf2a6.cloudfunctions.net/serviceDesk/io/incidentsByAgent`, params, headers)
            //  axios.post(`${process.env.REACT_APP_API_URL}/serviceDesk/io/incidentsByAgent`, params, headers)
            .then(data => {
                let responseArray = data.data
                responseArray.forEach(singleTicket => {
                    singleTicket.type = getType(singleTicket.type_id);
                    singleTicket.status = getStatus(singleTicket.status_id);
                    singleTicket.priority = getPriority(singleTicket.priority_id);
                });
                resolve(responseArray);
            }).catch(e => {
                return []
            })
    })
}

const Tasksclient = () => {

    const [darkmode, setDarkmode] = useState(false);
    const [screenSize, setScreenSize] = useState(window.innerWidth)
    const [tickets, setTickets] = useState([]);
    const [loader, setLoader] = useState(true);

    useEffect(() => {
        dbData().then(response => {
            setTickets(response); setLoader(false)
        }).catch(e => {
            console.error(`Error recuperando los tickets: ${e}`);
        });
    }, [])

    const handleDarkMode = () => {
        setDarkmode(!darkmode);
    };

    const handleClick = (e) => {
        Swal.fire({
            title: 'Comments for the task?',
            showCancelButton: 'true',
            showDenyButton: 'true',
            confirmButtonText: 'Close task',
            cancelButtonText: 'Cancel',
            denyButtonText: `Still open`,
            input: 'text',
            confirmButtonColor: '#4dc2f1',
            // cancelButtonColor: '#d33',
            reverseButtons: true,
            inputAttributes: {
                autocapitalize: 'off'
            },
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    title: 'Completed!',
                    text: 'Your task has been completed.',
                    icon: 'success',
                    confirmButtonColor: '#4dc2f1',
                })
            } else if (result.isDenied) {
                Swal.fire('Task still open', '', 'info')
            }
        })
    };

    return (
        <div>
            <Sidebar />
            <Tasks
                screenSize={screenSize}
                darkModeState={darkmode}
                handleClick={handleClick}
                handleDarkMode={handleDarkMode}
                tickets={tickets}
                loader={loader}
            />
        </div>
    );
}

export default Tasksclient;