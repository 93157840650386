import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import Assets from "../../components/create/Assets";
import Sidebar from "../../containers/Sidebar";
import Swal from 'sweetalert2'
import firebase from '../../config/firebaseTest'

const db = firebase.firestore()

const CreateAssetsClient = () => {

    const history = useHistory();

    const [darkmode, setDarkmode] = useState(false);
    const [screenSize, setScreenSize] = useState(window.innerWidth);
    const [name, setName] = useState('');
    const [type, setType] = useState('Hardware');
    const [optionSelected, setOptionSelected] = useState('option1');
    const [loader, setLoader] = useState(false);

    const handleDarkMode = () => {
        setDarkmode(!darkmode);
    };

    const handleChange = (e, state) => {
        if (state === 'name') {
            const name = e.target.value;
            setName(name);
        } else if (state === 'type') {
            const type = e.target.name;
            const option = e.target.value;
            setType(type);
            setOptionSelected(option)
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoader(true)
        db.collection("assets").doc("46RJ5pHTIHYewnESdrnT").collection("assets").add({
            name, type,
        }).then(() => {
            Swal.fire({
                title: 'Created!',
                text: 'The asset has been created.',
                icon: 'success',
                confirmButtonColor: '#4dc2f1',
                confirmButtonText: 'Continue',
            })
            history.push(`/assets`);
        })
    };

    return (
        <div>
            <Sidebar />
            <Assets
                screenSize={screenSize}
                darkModeState={darkmode}
                handleSubmit={handleSubmit}
                handleDarkMode={handleDarkMode}
                loader={loader}
                name={name}
                type={type}
                handleChange={handleChange}
                optionSelected={optionSelected}
            />
        </div>
    );
}

export default CreateAssetsClient;