import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import axios from "axios";
import Chat from "../components/Chat";
import ChatPeople from "../components/ChatPeople";
import Sidebar from "../containers/Sidebar";
import authHeader from "../services/auth-header";

const ChatClient = () => {
    let responsesPoc = [
        { message: `Por favor confirma que necesitas cambiar la contraseña.`, owner: 'admin' },
        { message: `Para cambiar la contraseña, por favor, revisa tu correo.`, owner: 'admin' }
    ]
    const messageEl = useRef(null);
    const loginUser = useSelector(state => state.user.loginUser)
    const { idBot } = useParams();
    const [darkmode, setDarkmode] = useState(false);
    const [screenSize, setScreenSize] = useState(window.innerWidth);
    const [lang, setLang] = useState('spanish');
    const [message, setMessage] = useState('');
    const [messages, setMessages] = useState([]);
    const [active, setActive] = useState(true);
    const [idx, setIdx] = useState(0);
    const [sessionPath, setSessionPath] = useState('');

    useEffect(() => {
        if (messageEl) {
            messageEl.current.addEventListener('DOMNodeInserted', event => {
                const { currentTarget: target } = event;
                target.scroll({ top: target.scrollHeight, behavior: 'smooth' });
            });
        }
    }, [])

    const handleDarkMode = () => {
        setDarkmode(!darkmode);
    };

    const handleSend = async (e) => {
        e.preventDefault();
        if (idBot === "people-management") {
            if (message.length > 0) {
                setActive(false);
                var msgs = messages;
                // msgs = [{ text: message, type: 0 }, ...messages];
                msgs = [...msgs, { message: message, owner: 'user' }];
                setMessages(msgs);
                const tmp = message;
                setMessage('');
                //    const url = process.env.REACT_APP_API_URL;
                const url = `https://us-central1-test-bf2a6.cloudfunctions.net`;
                // msgs = [{ text: "", type: 2 }, ...msgs];
                //   msgs = [...msgs, { message: ``, owner: 'admin' }];
                setMessages(msgs);
                const headers = { headers: authHeader() }
                const response = await axios.post(url + '/chatbot/insideone/manteniance',
                    { message: tmp, sessionPath: sessionPath, lang: 'es' }, headers);
                //   console.log('respuesta', response)
                let responseGoogle = response.data.detail.text;
                if (!sessionPath) setSessionPath(response.data.detail.sessionPath);
                //msgs[0] = [...msgs, { message: responseGoogle, owner: 'admin' }];
                msgs = [...msgs, { message: responseGoogle, owner: 'admin' }];
                setMessages(msgs);
                setActive(true);
                //  inputRef.current.focus();
            }
        } else {
            if (message.length > 0) {
                setActive(false);
                var msgs = messages;
                msgs = [...msgs, { message: message, owner: 'user' }];
                setMessages(msgs);
                setMessage('');
                msgs = [...msgs, { message: `${responsesPoc[idx].message}`, owner: 'admin' }];
                setMessages(msgs);
                setIdx(idx + 1);
                if (idx < 1) setActive(true);
                else {
                    const url = `https://us-central1-test-bf2a6.cloudfunctions.net`;
                    const headers = { headers: authHeader() }
                    axios.post(url + '/mailing/insideone/reset',
                        { lang: 'es' }, headers);
                } // LLAMAR a enviar mail de reset
                //    inputRef.current.focus();
            }
        }
    }

    return (
        <div>
            <Sidebar />
            {idBot === "people-management" ? (
                <ChatPeople
                    screenSize={screenSize}
                    darkModeState={darkmode}
                    handleDarkMode={handleDarkMode}
                    messages={messages}
                    message={message}
                    setMessage={setMessage}
                    typeBot={idBot}
                    loginUser={loginUser}
                    handleSend={handleSend}
                    active={active}
                    messageEl={messageEl}
                />
            ) : (
                <Chat
                    screenSize={screenSize}
                    darkModeState={darkmode}
                    handleDarkMode={handleDarkMode}
                    messages={messages}
                    message={message}
                    setMessage={setMessage}
                    typeBot={idBot}
                    loginUser={loginUser}
                    handleSend={handleSend}
                    active={active}
                    messageEl={messageEl}
                />
            )}

        </div>
    );
}

export default ChatClient;