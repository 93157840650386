import React from "react";
import Sidebar from "../components/SidebarJML";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { logoutUser } from "../store/actions/loginAction";
import AuthService from "../services/auth.service";

const SidebarContainer = () => {

  const history = useHistory();
  const dispatch = useDispatch()
  const loginUser = useSelector(state => state.user.loginUser)

  const handleLogout = (e) => {
    AuthService.logout()
    dispatch(logoutUser())
    history.push("/login");
  };

  return (
    <div>
      <Sidebar
        handleLogout={handleLogout}
        loginUser={loginUser}
      />
    </div>
  );
}

export default SidebarContainer;
