import firebase from '../../config/firebaseTest'
import { useHistory } from "react-router-dom";

const db = firebase.firestore()

export const loginUser = (userUID, historyRoute) => (dispatch) => {

  // const history = useHistory();
  db.collection("users").doc(userUID)
    .get()
    .then((user) => {
      dispatch({
        type: "SET_LOGIN",
        user: {
          userUID: userUID,
          firstName: user.data().firstName,
          lastName: user.data().lastName,
          rol: user.data().rol,
        },
      });
    })
    .then(() => historyRoute.push("/home"));
};

export const logoutUser = () => (dispatch) =>
  dispatch({
    type: "SET_LOGOUT",
    user: {},
  });