import React, { useState } from "react";
import SingleRol from "../components/SingleRol";
import Sidebar from "../containers/Sidebar";
import Swal from 'sweetalert2'

const SingleRolClient = () => {

    const [darkmode, setDarkmode] = useState(false);
    const [screenSize, setScreenSize] = useState(window.innerWidth);
    const [edit, setEdit] = useState(false);

    const handleDarkMode = () => {
        setDarkmode(!darkmode);
    };

    const handleSubmit = (e) => {
        if (e) {
            e.preventDefault();
        }
        setEdit(!edit);
        if (edit) {
            //Save rol at Firebase
            //
            Swal.fire({
                title: 'Saved!',
                text: 'The rol has been saved.',
                icon: 'success',
                confirmButtonColor: '#4dc2f1',
                confirmButtonText: 'Continue',
            })
        }

    };

    return (
        <div>
            <Sidebar />
            <SingleRol
                screenSize={screenSize}
                darkModeState={darkmode}
                handleSubmit={handleSubmit}
                handleDarkMode={handleDarkMode}
                edit={edit}
            />
        </div>
    );
}

export default SingleRolClient;