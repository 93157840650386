import React from "react";
import { Link } from "react-router-dom";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";

export default ({ screenSize, darkModeState, handleClick, assets, loader }) => {
    const styles = {
        container: {
            position: "fixed",
            bottom: 0,
            top: 0,
            left: 0,
            right: 0,
            display: "flex",
            flexDirection: "column",
            //   padding: `${screenSize > 800 ? ("3% 2% 3% 20%") : ("30px")}`,
            padding: `${screenSize > 800 ? ("3% 2% 3% 280px") : ("30px")}`,
            height: "100%",
            overflow: `auto`,
        },
        containerLoader: {
            display: "flex",
            alignItem: 'center',
            justifyContent: 'center',
            height: "80%",
        },
        navbar: {
            display: "flex",
            flex: 1.5,
            margin: `${screenSize > 800 ? ("0px 20px") : ("0px")}`,
            marginBottom: `${screenSize > 800 ? ("0px") : ("30px")}`,
        },
        textsNavbar: {
            display: "flex",
            flex: 8,
            flexDirection: "column",
        },
        titleNavbar: {
            marginBottom: `${screenSize > 800 ? ('5px') : ("10px")}`,
            fontSize: "28px",
            color: `${darkModeState ? ("hsl(0, 0%, 100%)") : ("hsl(230, 17%, 14%)")}`,
            fontFamily: "InterBold",
            lineHeight: '1.4'
        },
        subtitleNavbar: {
            margin: 0,
            fontSize: "14px",
            color: `${darkModeState ? ("hsl(228, 34%, 66%)") : ("hsl(228, 12%, 44%)")}`,
            fontFamily: "InterBold",
        },
        toogleNavbar: {
            display: "flex",
            flex: 4,
            alignItems: "center",
            justifyContent: `${screenSize > 800 ? ("flex-end") : ("center")}`,
            flexDirection: `${screenSize > 800 ? ("row") : ("column")}`,
        },
        textToogle: {
            fontSize: "14px",
            fontFamily: "InterBold",
            alignSelf: "center",
            textDecoration: "none",
            color: 'inherit',
            border: 'none',
            outline: 'none',
            padding: '12px',
            backgroundColor: `transparent`
        },
        textUser: {
            fontSize: "14px",
            fontFamily: "InterBold",
            alignSelf: "center",
            textDecoration: "none",
            color: 'inherit',
            border: 'none',
            outline: 'none',
            padding: 0,
            backgroundColor: `transparent`
        },
        buttonNew: {
            textDecoration: "none",
            color: "#fff",
            backgroundColor: "#4dc2f1",
            border: 'none',
            margin: "20px",
            // margin: `${screenSize > 800 ? ('1.4%') : ("10px")}`,
            width: `${screenSize > 800 ? ('180px') : (<></>)}`,
        },
        assets: {
            display: "flex",
            flex: 11,
            flexDirection: "column",
            alignItems: 'center',
            margin: `${screenSize > 800 ? ("0px 20px") : ("0px")}`,
            //  justifyContent: 'space-between',
            //   margin: `${screenSize > 800 ? ('20px 0px') : ("0px 0px 30px")}`,
        },
        singleAsset: {
            display: "flex",
            //   width: `${screenSize > 800 ? ("95%") : ("80vw")}`,
            width: `${screenSize > 800 ? ("100%") : ("80vw")}`,
            flexDirection: "column",
            alignItems: 'space-between',
            justifyContent: 'space-between',
            borderRadius: '5px',
            padding: "25px",
            textDecoration: "none",
            border: 'none',
            outline: 'none',
            margin: `${screenSize > 800 ? ('1.4% 0px -10px') : ("10px")}`,
        },
        lineDetail: {
            display: "flex",
            alignItems: 'space-between',
            justifyContent: 'space-between',
        },
        nameAsset: {
            display: "flex",
            color: `${darkModeState ? ("hsl(0, 0%, 100%)") : ("hsl(230, 17%, 14%)")}`,
            margin: 0,
            fontSize: "18px",
            fontFamily: "InterBold",
        },
        typeAsset: {
            display: "flex",
            color: `${darkModeState ? ("hsl(228, 34%, 66%)") : ("hsl(228, 12%, 44%)")}`,
            margin: 0,
            fontSize: "14px",
            fontFamily: "InterBold",
            textAlign: 'center'
        },
        loader: {
            display: 'flex',
            alignSelf: 'center'
        }
    };

    return (
        <div style={styles.container}>
            <div style={styles.navbar}>
                <div style={styles.textsNavbar}>
                    <h3 style={styles.titleNavbar}>Current Assets</h3>
                    <h3 style={styles.subtitleNavbar}>All company assets</h3>
                </div>
            </div>
            <Link style={styles.buttonNew} className="btn btn-primary" to="/assets/create">New asset</Link>
            <div style={styles.assets}>
                {loader ? (
                    <div style={styles.containerLoader}>
                        <Loader
                            style={styles.loader}
                            type="TailSpin"
                            color="#4dc2f1"
                            height={100}
                            width={100}
                            timeout={10000} //10 seconds
                        />
                    </div>
                ) : (
                    <>
                        {assets ? (
                            assets.map((singleAsset) => {
                                return (
                                    <div className={darkModeState ? ("buttonDark") : ("buttonLight")} style={styles.singleAsset} onClick={() => handleClick(`${singleAsset.id}`)} >
                                        <div style={styles.lineDetail}>
                                            <div style={styles.nameAsset}>{singleAsset.name}</div>
                                            <div style={styles.typeAsset}>{singleAsset.type}</div>
                                        </div>
                                    </div>
                                )
                            })) : (null)}
                    </>
                )}
            </div>
        </div>
    );
};
