import React, { useState } from "react";
import SingleAsset from "../components/SingleAsset";
import Sidebar from "../containers/Sidebar";
import Swal from 'sweetalert2'

const SingleAssetClient = () => {

    const [darkmode, setDarkmode] = useState(false);
    const [screenSize, setScreenSize] = useState(window.innerWidth);
    const [edit, setEdit] = useState(false);

    const handleDarkMode = () => {
        setDarkmode(!darkmode);
    };

    const handleSubmit = (e) => {
        if (e) {
            e.preventDefault();
        }
        setEdit(!edit);
        if (edit) {
            //Save asset at Firebase
            //
            Swal.fire({
                title: 'Saved!',
                text: 'The asset has been saved.',
                icon: 'success',
                confirmButtonColor: '#4dc2f1',
                confirmButtonText: 'Continue',
            })
        }

    };

    return (
        <div>
            <Sidebar />
            <SingleAsset
                screenSize={screenSize}
                darkModeState={darkmode}
                handleSubmit={handleSubmit}
                handleDarkMode={handleDarkMode}
                edit={edit}
            />
        </div>
    );
}

export default SingleAssetClient;