import React from "react";
import { Link } from "react-router-dom";


export default ({ screenSize, darkModeState, handleSubmit, edit }) => {

    const styles = {
        backColor: {
            position: "fixed",
            left: 0,
            top: 0,
            right: 0,
            bottom: 0,
            height: "100%",
            backgroundColor: `${darkModeState ? ("hsl(230, 17%, 14%)") : ("hsl(0, 0%, 100%)")}`
        },
        topBackColor: {
            position: "fixed",
            left: 0,
            top: 0,
            right: 0,
            bottom: 0,
            height: "29%",
            backgroundColor: `${darkModeState ? ("hsl(232, 19%, 15%)") : ("hsl(225, 100%, 98%)")}`,
            borderRadius: "0px 0px 25px 25px"
        },
        container: {
            position: "fixed",
            bottom: 0,
            top: 0,
            left: 0,
            right: 0,
            display: "flex",
            flexDirection: "column",
            //   padding: `${screenSize > 800 ? ("3% 2% 3% 20%") : ("30px")}`,
            padding: `${screenSize > 800 ? ("3% 2% 3% 280px") : ("30px")}`,
            height: "100%",
            overflow: `auto`,
        },
        navbar: {
            display: "flex",
            flex: 1.5,
            margin: `${screenSize > 800 ? ("0px 20px") : ("0px")}`,
            marginBottom: `${screenSize > 800 ? ("0px") : ("30px")}`,
        },
        textsNavbar: {
            display: "flex",
            flex: 8,
            flexDirection: "column",
        },
        titleNavbar: {
            marginBottom: `${screenSize > 800 ? ('5px') : ("10px")}`,
            fontSize: "28px",
            color: `${darkModeState ? ("hsl(0, 0%, 100%)") : ("hsl(230, 17%, 14%)")}`,
            fontFamily: "InterBold",
            lineHeight: '1.4'
        },
        subtitleNavbar: {
            margin: 0,
            fontSize: "14px",
            color: `${darkModeState ? ("hsl(228, 34%, 66%)") : ("hsl(228, 12%, 44%)")}`,
            fontFamily: "InterBold",
        },
        toogleNavbar: {
            display: "flex",
            flex: 4,
            alignItems: "center",
            justifyContent: `${screenSize > 800 ? ("flex-end") : ("center")}`,
            flexDirection: `${screenSize > 800 ? ("row") : ("column")}`,
        },
        textToogle: {
            fontSize: "14px",
            fontFamily: "InterBold",
            alignSelf: "center",
            textDecoration: "none",
            color: 'inherit',
            border: 'none',
            outline: 'none',
            padding: '12px',
            backgroundColor: `transparent`
        },
        textUser: {
            fontSize: "14px",
            fontFamily: "InterBold",
            alignSelf: "center",
            textDecoration: "none",
            color: 'inherit',
            border: 'none',
            outline: 'none',
            padding: 0,
            backgroundColor: `transparent`
        },
        buttons: {
            display: "flex",
            flexDirection: 'row',
            margin: 0
        },
        buttonEdit: {
            textDecoration: "none",
            color: "#fff",
            backgroundColor: "#4dc2f1",
            border: 'none',
            margin: "20px",
            // margin: `${screenSize > 800 ? ('1.4%') : ("10px")}`,
            width: `${screenSize > 800 ? ('180px') : (<></>)}`,
        },
        buttonDelete: {
            textDecoration: "none",
            color: "#fff",
            backgroundColor: "hsl(356, 69%, 56%)",
            border: 'none',
            margin: "20px 0px",
            // margin: `${screenSize > 800 ? ('1.4%') : ("10px")}`,
            width: `${screenSize > 800 ? ('180px') : (<></>)}`,
        },
        roles: {
            display: "flex",
            flex: 11,
            flexDirection: "column",
            alignItems: 'center',
            margin: `${screenSize > 800 ? ("0px 20px") : ("0px")}`,
            //  justifyContent: 'space-between',
            //   margin: `${screenSize > 800 ? ('20px 0px') : ("0px 0px 30px")}`,
        },
        singleRol: {
            display: "flex",
            //   width: `${screenSize > 800 ? ("95%") : ("80vw")}`,
            width: `${screenSize > 800 ? ("100%") : ("80vw")}`,
            flexDirection: "column",
            alignItems: 'space-between',
            justifyContent: 'space-between',
            borderRadius: '5px',
            padding: "25px",
            textDecoration: "none",
            border: 'none',
            outline: 'none',
            margin: `${screenSize > 800 ? ('1.4%') : ("10px")}`,
        },
        lineDetailCenter: {
            display: "flex",
            alignItems: 'space-between',
            justifyContent: 'space-between',
            margin: `12px 0px`,
        },
        lineDetail: {
            display: "flex",
            alignItems: 'space-between',
            justifyContent: 'space-between',
        },
        nameRole: {
            display: "flex",
            color: `${darkModeState ? ("hsl(0, 0%, 100%)") : ("hsl(230, 17%, 14%)")}`,
            margin: 0,
            fontSize: "18px",
            fontFamily: "InterBold",
        },
        quantityAssets: {
            display: "flex",
            color: `${darkModeState ? ("hsl(228, 34%, 66%)") : ("hsl(228, 12%, 44%)")}`,
            margin: 0,
            fontSize: "14px",
            fontFamily: "InterBold",
        },
        quantityPeople: {
            display: "flex",
            color: `${darkModeState ? ("hsl(228, 34%, 66%)") : ("hsl(228, 12%, 44%)")}`,
            margin: "0px 6px",
            fontSize: "14px",
            fontFamily: "InterBold",
        },
        form: {
            width: '100%',
            margin: '10px 0px'
        },
        label: {
            margin: '0px 0px 15px',
            fontSize: "18px",
            fontFamily: "InterBold",
            color: `${darkModeState ? ("hsl(228, 34%, 66%)") : ("hsl(228, 12%, 44%)")} `,
        },
        input: {
            fontFamily: "InterRegular",
            fontSize: "16px",
            color: `${darkModeState ? ("hsl(228, 34%, 66%)") : ("hsl(228, 12%, 44%)")} `,
            margin: '0px 0px 25px',
        },
        checkbox: {
            display: 'flex',
            alignItems: 'center',
            margin: '0px 0px 10px 3px'
        },
        labelCheckbox: {
            fontFamily: "InterRegular",
            fontSize: "16px",
            color: `${darkModeState ? ("hsl(228, 34%, 66%)") : ("hsl(228, 12%, 44%)")} `,
        },
        inputCheckbox: {
            fontFamily: "InterRegular",
            fontSize: "16px",
            border: '1px solid red',
        },
    };

    return (
        <>
            <div style={styles.backColor} />
            <div style={styles.container}>
                <div style={styles.navbar}>
                    <div style={styles.textsNavbar}>
                        <h3 style={styles.titleNavbar}>Manager</h3>
                        <h3 style={styles.subtitleNavbar}>This is the Manager Rol</h3>
                    </div>
                </div>
                <div style={styles.buttons}>
                    {edit ? (<button type="submit" className="btn" style={styles.buttonEdit} onClick={() => { handleSubmit() }}>Save rol</button>
                    ) : (<button type="submit" className="btn" style={styles.buttonEdit} onClick={() => { handleSubmit() }}>Edit rol</button>)}


                    <Link style={styles.buttonDelete} className="btn btn-primary"
                    //to={`/roles/edit/idSingleRol`}
                    >Delete rol</Link>
                </div>
                {/* <div style={styles.roles}>
                    <div className={darkModeState ? ("buttonDark") : ("buttonLight")} style={styles.singleRol} onClick={() => handleClick("idManager")} >
                        <div style={styles.lineDetailCenter}>
                            <div style={styles.nameRole}>Manager</div>
                        </div>
                        <div style={styles.lineDetail}>
                            <div style={styles.quantityAssets}>6 Assets</div>
                            <div style={styles.quantityPeople}>10 People</div>
                        </div>
                    </div>
                </div> */}
                <div style={styles.roles}>
                    <form
                        onSubmit={(e) => handleSubmit(e)}
                        style={styles.form}>
                        <div className="form-group" >
                            <label style={styles.label}>Name of rol</label>
                            <input
                                //                onChange={(e) => input(e)}
                                name="nameOfRol" type="text" className="form-control" style={styles.input} placeholder="Name of rol..." required disabled={edit ? (false) : (true)} />
                            <label style={styles.label}>Assets</label>
                            <div class="form-check" style={styles.checkbox}>
                                <input class="form-check-input" style={styles.inputCheckbox} type="checkbox" value="" id="flexCheckChecked1" disabled={edit ? (false) : (true)} />
                                <label class="form-check-label" style={styles.labelCheckbox} for="flexCheckChecked1">Credit Card</label>
                            </div>
                            <div class="form-check" style={styles.checkbox}>
                                <input class="form-check-input" style={styles.inputCheckbox} type="checkbox" value="" id="flexCheckChecked2" disabled={edit ? (false) : (true)} />
                                <label class="form-check-label" style={styles.labelCheckbox} for="flexCheckChecked2">Car</label>
                            </div>
                            <div class="form-check" style={styles.checkbox}>
                                <input class="form-check-input" style={styles.inputCheckbox} type="checkbox" value="" id="flexCheckChecked3" disabled={edit ? (false) : (true)} />
                                <label class="form-check-label" style={styles.labelCheckbox} for="flexCheckChecked3">Computer</label>
                            </div>
                            <div class="form-check" style={styles.checkbox}>
                                <input class="form-check-input" style={styles.inputCheckbox} type="checkbox" value="" id="flexCheckChecked4" disabled={edit ? (false) : (true)} />
                                <label class="form-check-label" style={styles.labelCheckbox} for="flexCheckChecked4">Access Point</label>
                            </div>
                            <div class="form-check" style={styles.checkbox}>
                                <input class="form-check-input" style={styles.inputCheckbox} type="checkbox" value="" id="flexCheckChecked5" disabled={edit ? (false) : (true)} />
                                <label class="form-check-label" style={styles.labelCheckbox} for="flexCheckChecked5">Cellphone</label>
                            </div>
                            <div class="form-check" style={styles.checkbox}>
                                <input class="form-check-input" style={styles.inputCheckbox} type="checkbox" value="" id="flexCheckChecked6" disabled={edit ? (false) : (true)} />
                                <label class="form-check-label" style={styles.labelCheckbox} for="flexCheckChecked6">Tablet</label>
                            </div>
                            <div class="form-check" style={styles.checkbox}>
                                <input class="form-check-input" style={styles.inputCheckbox} type="checkbox" value="" id="flexCheckChecked7" disabled={edit ? (false) : (true)} />
                                <label class="form-check-label" style={styles.labelCheckbox} for="flexCheckChecked7">Extra Monitor</label>
                            </div>
                            <div class="form-check" style={styles.checkbox}>
                                <input class="form-check-input" style={styles.inputCheckbox} type="checkbox" value="" id="flexCheckChecked8" disabled={edit ? (false) : (true)} />
                                <label class="form-check-label" style={styles.labelCheckbox} for="flexCheckChecked8">Ip Camera</label>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
};
