import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import Roles from "../components/Roles";
import Sidebar from "../containers/Sidebar";
import Swal from 'sweetalert2'
import firebase from '../config/firebaseTest'

const db = firebase.firestore()

const RolesClient = () => {

    const history = useHistory();

    const [darkmode, setDarkmode] = useState(false);
    const [screenSize, setScreenSize] = useState(window.innerWidth);
    const [roles, setRoles] = useState([]);
    const [loader, setLoader] = useState(true);

    useEffect(() => {
        let rolesToState = []
        db.collection("roles").doc("46RJ5pHTIHYewnESdrnT").collection("roles").orderBy("name")
            .get()
            .then((roles) => {
                roles.forEach((singleRol) => {
                    rolesToState.push({
                        id: singleRol.id,
                        name: singleRol.data().name,
                        assets: singleRol.data().assets,
                        people: singleRol.data().people
                    })
                });
            }).then(() => { setRoles(rolesToState); setLoader(false) })
    }, [])

    const handleDarkMode = () => {
        setDarkmode(!darkmode);
    };

    const handleClick = (idRoleSelected) => {
        history.push(`/roles/${idRoleSelected}`);
    };

    return (
        <div>
            <Sidebar />
            <Roles
                screenSize={screenSize}
                darkModeState={darkmode}
                handleClick={handleClick}
                handleDarkMode={handleDarkMode}
                roles={roles}
                loader={loader}
            />
        </div>
    );
}

export default RolesClient;