import React from "react";
import { Link } from "react-router-dom";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";


export default ({ screenSize, darkModeState, handleClick, tickets, loader }) => {

    const sampleData = [
        { id: 1, titulo: "Problemas de acceso al CRM", tipo: "Incidente", prioridad: "Baja", estado: "Abierto" },
        { id: 2, titulo: "Solicitud de cambio de domicilio", tipo: "Pedido de servicio", prioridad: "Urgente", estado: "Abierto" },
        { id: 3, titulo: "Los elevadores del ala derecha no funcionan", tipo: "Incidente", prioridad: "Media", estado: "Abierto" },
        { id: 4, titulo: "Lentitud en control remoto", tipo: "Incidente", prioridad: "Baja", estado: "Abierto" },
        { id: 5, titulo: "Nuevo usuario en herramienta de PM", tipo: "Pedido de servicio", prioridad: "Baja", estado: "Abierto" },
        { id: 6, titulo: "El sitio web tiene textos confusos", tipo: "Incidente", prioridad: "Alta", estado: "Abierto" },
        { id: 7, titulo: "Solicitud de acceso a red pública FiberCorp", tipo: "Pedido de servicio", prioridad: "Media", estado: "Abierto" },
        { id: 8, titulo: "Consulta de seguimiento de envío", tipo: "Pedido de servicio", prioridad: "Critica", estado: "Abierto" },
        { id: 9, titulo: "Baja de empleado", tipo: "Incidente", prioridad: "Baja", estado: "Abierto" },
        { id: 10, titulo: "Solicitud de acceso a VPN PCI2", tipo: "Pedido de servicio", prioridad: "media", estado: "Abierto" },
    ];
    const sampleData2 = [
        { id: 11, description: "Lentitud en control remoto", type: "Incidente", priority: "Baja", status: "Abierto" },
        { id: 12, description: "Problemas de acceso al CRM", type: "Incidente", priority: "Baja", status: "Abierto" },
        // { id: 13, description: "Los elevadores del ala derecha no funcionan", type: "Incidente", priority: "Media", status: "Abierto" },
        // { id: 14, description: "Solicitud de acceso a VPN PCI2", type: "Pedido de servicio", priority: "Media", status: "Abierto" },
        // { id: 15, description: "Nuevo usuario en herramienta de PM", type: "Pedido de servicio", priority: "Baja", status: "Abierto" },
        // { id: 16, description: "Solicitud de cambio de domicilio", type: "Pedido de servicio", priority: "Urgente", status: "Abierto" },
        // { id: 17, description: "Consulta de seguimiento de envío", type: "Pedido de servicio", priority: "Critica", status: "Abierto" },
        // { id: 18, description: "El sitio web tiene textos confusos", type: "Incidente", priority: "Alta", status: "Abierto" },
        // { id: 19, description: "Baja de empleado", type: "Incidente", priority: "Baja", status: "Abierto" },
        // { id: 20, description: "Solicitud de acceso a red pública FiberCorp", type: "Pedido de servicio", priority: "Media", status: "Abierto" }
    ];

    const styles = {
        container: {
            position: "fixed",
            bottom: 0,
            top: 0,
            left: 0,
            right: 0,
            display: "flex",
            flexDirection: "column",
            //   padding: `${screenSize > 800 ? ("3% 2% 3% 20%") : ("30px")}`,
            padding: `${screenSize > 800 ? ("3% 2% 3% 280px") : ("30px")}`,
            height: "100%",
            overflow: `auto`,
        },
        containerLoader: {
            display: "flex",
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%'
        },
        loader: {
            display: 'flex',
            alignSelf: 'center'
        },
        navbar: {
            display: "flex",
            flex: 1.5,
            margin: `${screenSize > 800 ? ("0px 20px") : ("0px")}`,
            marginBottom: `${screenSize > 800 ? ("0px") : ("30px")}`,
        },
        textsNavbar: {
            display: "flex",
            flex: 8,
            flexDirection: "column",
        },
        titleNavbar: {
            marginBottom: `${screenSize > 800 ? ('5px') : ("10px")}`,
            fontSize: "28px",
            color: `${darkModeState ? ("hsl(0, 0%, 100%)") : ("hsl(230, 17%, 14%)")}`,
            fontFamily: "InterBold",
            lineHeight: '1.4'
        },
        subtitleNavbar: {
            margin: 0,
            fontSize: "14px",
            color: `${darkModeState ? ("hsl(228, 34%, 66%)") : ("hsl(228, 12%, 44%)")}`,
            fontFamily: "InterBold",
        },
        toogleNavbar: {
            display: "flex",
            flex: 4,
            alignItems: "center",
            justifyContent: `${screenSize > 800 ? ("flex-end") : ("center")}`,
            flexDirection: `${screenSize > 800 ? ("row") : ("column")}`,
        },
        textToogle: {
            fontSize: "14px",
            fontFamily: "InterBold",
            alignSelf: "center",
            textDecoration: "none",
            color: 'inherit',
            border: 'none',
            outline: 'none',
            padding: '12px',
            backgroundColor: `transparent`
        },
        textUser: {
            fontSize: "14px",
            fontFamily: "InterBold",
            alignSelf: "center",
            textDecoration: "none",
            color: 'inherit',
            border: 'none',
            outline: 'none',
            padding: 0,
            backgroundColor: `transparent`
        },
        indicators: {
            display: "flex",
            flex: 11,
            flexDirection: "column",
        },
        generalIndicators: {
            display: "flex",
            flex: 2,
            alignItems: 'center',
            justifyContent: 'space-between',
            flexWrap: `${screenSize > 800 ? ('no-wrap') : ("wrap")}`,
            margin: `${screenSize > 800 ? ('20px 0px') : ("0px 0px 30px")}`,
        },
        singleGenIndicator: {
            display: "flex",
            width: `${screenSize > 800 ? ("22%") : ("80vw")}`,
            flexDirection: "column",
            alignItems: 'stretch',
            borderRadius: '5px',
            padding: '0px',
            textDecoration: "none",
            border: 'none',
            outline: 'none',
            margin: `${screenSize > 800 ? ('1.4%') : ("20px 10px")}`,
        },
        borderCardT: {
            display: "flex",
            height: '4px',
            width: '100%',
            flexDirection: "column",
            backgroundColor: "hsl(195, 100%, 50%)",
            borderRadius: '5px 5px 0px 0px',
        },
        contentSingleIndicator: {
            display: "flex",
            height: '100%',
            //  flexDirection: "column",
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: '30px',
        },
        detailCard: {
            display: "flex",
            color: `${darkModeState ? ("hsl(228, 34%, 66%)") : ("hsl(228, 12%, 44%)")}`,
            margin: 0,
            fontSize: "12px",
            fontFamily: "InterRegular",
            letterSpacing: '5px',
            marginBottom: '20px'
        },
        detailIndicators: {
            display: "flex",
            flex: 9,
            flexDirection: "column",
        },
        titleDetailIndicators: {
            display: "flex",
            flex: 2,
            alignItems: 'center',
            fontSize: "24px",
            color: `${darkModeState ? ("hsl(0, 0%, 100%)") : ("hsl(228, 12%, 44%)")}`,
            fontFamily: "InterBold",
            margin: `${screenSize > 800 ? ("0px 20px 20px") : ("0px")}`,
        },
        rowDetailIndicators: {
            display: "flex",
            flex: 10,
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
            // flexDirection: "row",
            flexDirection: `${screenSize > 800 ? ('row') : ("column")}`,
            flexWrap: `${screenSize > 800 ? ('wrap') : ("wrap")}`,
            margin: `${screenSize > 800 ? ('0px') : ("20px 0px")}`,
        },
        columnDetailIndicators: {
            display: "flex",
            flex: 6,
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: "column",
            // flexWrap: `${screenSize > 800 ? ('wrap') : ("wrap")}`,
            margin: `${screenSize > 800 ? ('0px') : ("20px 0px")}`,
        },
        singleDetIndicator: {
            display: "flex",
            width: `${screenSize > 800 ? ("47%") : ("80vw")}`,
            flexDirection: "column",
            alignItems: 'space-between',
            justifyContent: 'space-between',
            borderRadius: '5px',
            padding: "25px",
            textDecoration: "none",
            border: 'none',
            outline: 'none',
            margin: `${screenSize > 800 ? ('1.4%') : ("10px")}`,
        },
        lineDetail: {
            display: "flex",
            alignItems: 'space-between',
            justifyContent: 'space-between',
        },
        lineDetailCenter: {
            display: "flex",
            alignItems: 'space-between',
            justifyContent: 'space-between',
            margin: `12px 0px`,
        },
        idDetailCard: {
            display: "flex",
            color: `${darkModeState ? ("hsl(228, 34%, 66%)") : ("hsl(228, 12%, 44%)")}`,
            margin: 0,
            fontSize: "12px",
            fontFamily: "InterRegular",
        },
        priorityDetailCard: {
            display: "flex",
            // color: `${darkModeState ? ("hsl(228, 34%, 66%)") : ("hsl(228, 12%, 44%)")}`,
            color: '#fff',
            margin: 0,
            fontSize: "10px",
            fontFamily: "InterRegular",
            padding: '3px 10px',
            backgroundColor: '#4dc2f1',
            borderRadius: '20px',
            width: '60px',
            alignItems: 'center',
            justifyContent: 'center'
        },
        descriptionDetailCard: {
            display: "flex",
            color: `${darkModeState ? ("hsl(0, 0%, 100%)") : ("hsl(230, 17%, 14%)")}`,
            margin: 0,
            fontSize: "18px",
            fontFamily: "InterBold",
        },
        typeDetailCard: {
            display: "flex",
            color: `${darkModeState ? ("hsl(228, 34%, 66%)") : ("hsl(228, 12%, 44%)")}`,
            margin: 0,
            fontSize: "14px",
            fontFamily: "InterBold",
        },
        statusDetailCard: {
            display: "flex",
            color: `${darkModeState ? ("hsl(228, 34%, 66%)") : ("hsl(228, 12%, 44%)")}`,
            margin: "0px 6px",
            fontSize: "14px",
            fontFamily: "InterBold",

        }
    };

    return (
        <>
            <div style={styles.container}>
                <div style={styles.navbar}>
                    <div style={styles.textsNavbar}>
                        <h3 style={styles.titleNavbar}>InsideOne Dashboard</h3>
                        <h3 style={styles.subtitleNavbar}>How can we help you?</h3>
                    </div>
                </div>
                {loader ? (
                    <div style={styles.containerLoader}>
                        <Loader
                            style={styles.loader}
                            type="TailSpin"
                            color="#4dc2f1"
                            height={100}
                            width={100}
                            timeout={10000} //10 seconds
                        />
                    </div>
                ) : (<>
                    <div style={styles.indicators}>
                        <div style={styles.generalIndicators}>
                            <Link className={darkModeState ? ("buttonDark") : ("buttonLight")} style={styles.singleGenIndicator} to="/bot/people-management">
                                <div style={styles.borderCardT} />
                                <div style={styles.contentSingleIndicator}>
                                    <div style={styles.textDetailCard}>People Management</div>
                                    <img
                                        src="https://i.postimg.cc/m2xp08F0/robot-1.png"
                                        //  src="https://i.postimg.cc/SRjc48Cv/Eric-Apuntando-Alpha-baja.gif"
                                        height="55"
                                        alt="Robot of Inside One"
                                    />
                                </div>
                            </Link>
                            <Link className={darkModeState ? ("buttonDark") : ("buttonLight")} style={styles.singleGenIndicator} to="/bot/requests">
                                <div style={styles.borderCardT} />
                                <div style={styles.contentSingleIndicator}>
                                    <div style={styles.textDetailCard}>Requests</div>
                                    <img
                                        src="https://i.postimg.cc/VstDBnH4/robot-2.png"
                                        height="55"
                                        alt="Robot of Inside One"
                                    />
                                </div>
                            </Link>
                            <Link className={darkModeState ? ("buttonDark") : ("buttonLight")} style={styles.singleGenIndicator} to="/bot/maintenance">
                                <div style={styles.borderCardT} />
                                <div style={styles.contentSingleIndicator}>
                                    <div style={styles.textDetailCard}>Maintenance</div>
                                    <img
                                        src="https://i.postimg.cc/MGXYcQQ7/robot-3.png"
                                        height="55"
                                        alt="Robot of Inside One"
                                    />
                                </div>
                            </Link>
                            <Link className={darkModeState ? ("buttonDark") : ("buttonLight")} style={styles.singleGenIndicator} to="/bot/support">
                                <div style={styles.borderCardT} />
                                <div style={styles.contentSingleIndicator}>
                                    <div style={styles.textDetailCard}>Support</div>
                                    <img
                                        src="https://i.postimg.cc/pXZQqZQQ/robot-4.png"
                                        height="55"
                                        alt="Robot of Inside One"
                                    />
                                </div>
                            </Link>
                        </div>
                        <div style={styles.detailIndicators}>
                            <h3 style={styles.titleDetailIndicators}>Overview - Tasks</h3>
                            <div style={styles.rowDetailIndicators}>
                                {tickets ? (
                                    tickets.map((ticket) => {
                                        return (
                                            <div key={ticket.id} className={darkModeState ? ("buttonDark") : ("buttonLight")} style={styles.singleDetIndicator} onClick={handleClick} >
                                                <div style={styles.lineDetail}>
                                                    <div style={styles.idDetailCard}>#{ticket.id}</div>
                                                    <div style={styles.priorityDetailCard}>{ticket.priority}</div>
                                                </div>
                                                <div style={styles.lineDetailCenter}>
                                                    <div style={styles.descriptionDetailCard}>{ticket.description}</div>
                                                </div>
                                                <div style={styles.lineDetail}>
                                                    <div style={styles.typeDetailCard}>{ticket.type}</div>
                                                    <div style={styles.statusDetailCard}>{ticket.status}</div>
                                                </div>
                                            </div>
                                        )
                                    })
                                ) : (<></>)}
                            </div>
                        </div>
                    </div>
                </>)}
            </div>
        </>
    );
};
