import React, { useState, useEffect } from "react";
import Dashboard from "../components/DashboardJML";
import Sidebar from "../containers/Sidebar";
import Swal from 'sweetalert2'
import axios from "axios";

import authHeader from "../services/auth-header";
import auth_service from "../services/auth.service"

const getStatus = (id) => {
    const ticket = {
        1: 'New',
        2: 'Open',
        3: 'Pending',
        4: 'Waiting',
        5: 'Solved',
        6: 'Closed'
    }
    return ticket[id];
}

const getPriority = (id) => {
    const ticket =
    {
        1: 'Low',
        2: 'Medium',
        3: 'High',
        4: 'Urgent',
        5: 'Critical'
    }
    return ticket[id];
}

const getType = (id) => {
    const ticket =
    {
        1: "Incident",
        2: "Service request",
        3: "Question",
        4: "Problem",
        5: "Change request",
        6: "Major incident"
    }
    return ticket[id];
}

const dbData = () => {
    return new Promise((resolve, reject) => {
        const user = auth_service.getCurrentUser();
        const params = { email: 'gjimenez@insideone.cloud' }
        //  const params = { email: user.email }  
        const headers = { headers: authHeader() }
        //  const headers = { headers: { Authorization: 'Bearer ' + 'eyJhbGciOiJSUzI1NiIsImtpZCI6IjE1MjU1NWEyMjM3MWYxMGY0ZTIyZjFhY2U3NjJmYzUwZmYzYmVlMGMiLCJ0eXAiOiJKV1QifQ.eyJpc3MiOiJodHRwczovL3NlY3VyZXRva2VuLmdvb2dsZS5jb20vdGVzdC1iZjJhNiIsImF1ZCI6InRlc3QtYmYyYTYiLCJhdXRoX3RpbWUiOjE2MzUyODQwMzcsInVzZXJfaWQiOiI3MzNoNXRwT3hxTnhvczRXa2JmUnVkbW1SWDMzIiwic3ViIjoiNzMzaDV0cE94cU54b3M0V2tiZlJ1ZG1tUlgzMyIsImlhdCI6MTYzNTI4NDAzNywiZXhwIjoxNjM1Mjg3NjM3LCJlbWFpbCI6ImFsZXhrQGluc2lkZW9uZS5jb20uYXIiLCJlbWFpbF92ZXJpZmllZCI6ZmFsc2UsImZpcmViYXNlIjp7ImlkZW50aXRpZXMiOnsiZW1haWwiOlsiYWxleGtAaW5zaWRlb25lLmNvbS5hciJdfSwic2lnbl9pbl9wcm92aWRlciI6InBhc3N3b3JkIn19.MrAtzrcQIG1vZxIhXEhJJ8EJV5yINDK0b8q0FgyBXMo474XdZMe-mz_37wyOtkeOkvw1OfXKIv6Hw9g56C8p7XhrhfPSM5EbZg-DTR9p8uctenUYB1y3AT4xZVRCvM0OPhW5yv2VRNARN2eOhUXdu73gAMTdQKJvsiorDzoJdbSoJZCMFyx4RZ82nP2DvBYRlt-fIbRDa8A7-eNaRyVumwGwh4e9MctVBrREVxvA_gICQB1CvjgEmOfQZf7NZ5QxsneR3XGg4RcIVxyY_PJ6jIrHMXQYd1tQNIID9xB0nnw-b5SG0OYeZ-c4N46RF9WRy5L93WMetoydMkcz5qzcnw' } }
        axios.post(`https://us-central1-test-bf2a6.cloudfunctions.net/serviceDesk/io/incidentsByAgent`, params, headers)
            //  axios.post(`${process.env.REACT_APP_API_URL}/serviceDesk/io/incidentsByAgent`, params, headers)
            .then(data => {
                let responseArray = data.data
                responseArray.forEach(singleTicket => {
                    singleTicket.type = getType(singleTicket.type_id);
                    singleTicket.status = getStatus(singleTicket.status_id);
                    singleTicket.priority = getPriority(singleTicket.priority_id);
                });
                resolve(responseArray);
            }).catch(e => {
                return []
            })
    })
}

const DashboardClient = () => {

    const [darkmode, setDarkmode] = useState(false);
    const [screenSize, setScreenSize] = useState(window.innerWidth)
    const [tickets, setTickets] = useState([]);
    const [loader, setLoader] = useState(true);

    useEffect(() => {
        dbData().then(response => {
            setTickets(response); setLoader(false)
        }).catch(e => {
            console.error(`Error recuperando los tickets: ${e}`);
        });
    }, [])

    const handleDarkMode = () => {
        setDarkmode(!darkmode);
    };

    const handleClick = (e) => {
        Swal.fire({
            title: 'Comments for the task?',
            showCancelButton: 'true',
            showDenyButton: 'true',
            confirmButtonText: 'Close task',
            cancelButtonText: 'Cancel',
            denyButtonText: `Still open`,
            input: 'text',
            confirmButtonColor: '#4dc2f1',
            // cancelButtonColor: '#d33',
            reverseButtons: true,
            inputAttributes: {
                autocapitalize: 'off'
            },
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    title: 'Completed!',
                    text: 'Your task has been completed.',
                    icon: 'success',
                    confirmButtonColor: '#4dc2f1',
                })
            } else if (result.isDenied) {
                Swal.fire('Task still open', '', 'info')
            }
        })
    };

    return (
        <div>
            <Sidebar />
            <Dashboard
                screenSize={screenSize}
                darkModeState={darkmode}
                handleClick={handleClick}
                handleDarkMode={handleDarkMode}
                tickets={tickets}
                loader={loader}
            />
        </div>
    );
}

export default DashboardClient;