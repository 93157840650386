import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import Roles from "../../components/create/Roles";
import Sidebar from "../../containers/Sidebar";
import Swal from 'sweetalert2'

const CreateRolesClient = () => {

    const history = useHistory();

    const [darkmode, setDarkmode] = useState(false);
    const [screenSize, setScreenSize] = useState(window.innerWidth);
    // const [content, setContent] = useState("");

    const handleDarkMode = () => {
        setDarkmode(!darkmode);
    };

    const handleSubmit = (e) => {
        if (e) {
            e.preventDefault()
        }
        Swal.fire({
            title: 'Created!',
            text: 'The rol has been created.',
            icon: 'success',
            confirmButtonColor: '#4dc2f1',
            confirmButtonText: 'Continue',
        })
        history.push(`/roles`);
    };

    return (
        <div>
            <Sidebar />
            <Roles
                screenSize={screenSize}
                darkModeState={darkmode}
                handleSubmit={handleSubmit}
                handleDarkMode={handleDarkMode}
            />
        </div>
    );
}

export default CreateRolesClient;