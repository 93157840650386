import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Assets from "../components/Assets";
import Sidebar from "../containers/Sidebar";
import firebase from '../config/firebaseTest'

const db = firebase.firestore()

const AssetsClient = () => {

    const history = useHistory();

    const [darkmode, setDarkmode] = useState(false);
    const [screenSize, setScreenSize] = useState(window.innerWidth);
    const [assets, setAssets] = useState([]);
    const [loader, setLoader] = useState(true);

    useEffect(() => {
        let assetsToState = []
        db.collection("assets").doc("46RJ5pHTIHYewnESdrnT").collection("assets").orderBy("type")
            .get()
            .then((assets) => {
                assets.forEach((singleAsset) => {
                    assetsToState.push({
                        id: singleAsset.id,
                        name: singleAsset.data().name,
                        type: singleAsset.data().type
                    })
                });
            }).then(() => { setAssets(assetsToState); setLoader(false) })
    }, [])


    const handleDarkMode = () => {
        setDarkmode(!darkmode);
    };

    const handleClick = (idAssetSelected) => {
        history.push(`/assets/${idAssetSelected}`);
    };

    return (
        <div>
            <Sidebar />
            <Assets
                screenSize={screenSize}
                darkModeState={darkmode}
                handleClick={handleClick}
                handleDarkMode={handleDarkMode}
                assets={assets}
                loader={loader}
            />
        </div>
    );
}

export default AssetsClient;