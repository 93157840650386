import React from "react";
import { Link } from "react-router-dom";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";

export default ({ screenSize, darkModeState, handleSubmit, handleDarkMode, loader, name, type, handleChange, optionSelected }) => {
    const styles = {
        container: {
            position: "fixed",
            bottom: 0,
            top: 0,
            left: 0,
            right: 0,
            display: "flex",
            flexDirection: "column",
            //   padding: `${screenSize > 800 ? ("3% 2% 3% 20%") : ("30px")}`,
            padding: `${screenSize > 800 ? ("3% 2% 3% 280px") : ("30px")}`,
            height: "100%",
            overflow: `auto`,
        },
        containerLoader: {
            display: "flex",
            alignItem: 'center',
            justifyContent: 'center',
            height: "80%",
        },
        navbar: {
            display: "flex",
            flex: 1.5,
            margin: `${screenSize > 800 ? ("0px 20px") : ("0px")}`,
            marginBottom: `${screenSize > 800 ? ("0px") : ("30px")}`,
        },
        textsNavbar: {
            display: "flex",
            flex: 8,
            flexDirection: "column",
            // justifyContent: 'center'
        },
        titleNavbar: {
            marginBottom: `${screenSize > 800 ? ('5px') : ("10px")}`,
            fontSize: "28px",
            color: `${darkModeState ? ("hsl(0, 0%, 100%)") : ("hsl(230, 17%, 14%)")}`,
            fontFamily: "InterBold",
            lineHeight: '1.4',
            //  margin: '0px'
        },
        subtitleNavbar: {
            margin: 0,
            fontSize: "14px",
            color: `${darkModeState ? ("hsl(228, 34%, 66%)") : ("hsl(228, 12%, 44%)")}`,
            fontFamily: "InterBold",
        },
        toogleNavbar: {
            display: "flex",
            flex: 4,
            alignItems: "center",
            justifyContent: `${screenSize > 800 ? ("flex-end") : ("center")}`,
            flexDirection: `${screenSize > 800 ? ("row") : ("column")}`,
        },
        textToogle: {
            fontSize: "14px",
            fontFamily: "InterBold",
            alignSelf: "center",
            textDecoration: "none",
            color: 'inherit',
            border: 'none',
            outline: 'none',
            padding: '12px',
            backgroundColor: `transparent`
        },
        textUser: {
            fontSize: "14px",
            fontFamily: "InterBold",
            alignSelf: "center",
            textDecoration: "none",
            color: 'inherit',
            border: 'none',
            outline: 'none',
            padding: 0,
            backgroundColor: `transparent`
        },
        roles: {
            display: "flex",
            flex: 11,
            flexDirection: "column",
            alignItems: 'center',
            margin: `${screenSize > 800 ? ("0px 20px") : ("0px")}`,
            //  justifyContent: 'space-between',
            //   margin: `${screenSize > 800 ? ('20px 0px') : ("0px 0px 30px")}`,
        },
        form: {
            width: '100%',
            // margin: '20px 0px'
        },
        label: {
            margin: '0px 0px 15px',
            fontSize: "18px",
            fontFamily: "InterBold",
            color: `${darkModeState ? ("hsl(228, 34%, 66%)") : ("hsl(228, 12%, 44%)")} `,
        },
        input: {
            fontFamily: "InterRegular",
            fontSize: "16px",
            color: `${darkModeState ? ("hsl(228, 34%, 66%)") : ("hsl(228, 12%, 44%)")} `,
            margin: '0px 0px 25px',
        },
        checkbox: {
            display: 'flex',
            alignItems: 'center',
            margin: '0px 0px 10px 3px'
        },
        labelCheckbox: {
            fontFamily: "InterRegular",
            fontSize: "16px",
            color: `${darkModeState ? ("hsl(228, 34%, 66%)") : ("hsl(228, 12%, 44%)")} `,
        },
        inputCheckbox: {
            fontFamily: "InterRegular",
            fontSize: "16px",
            border: '1px solid red',
        },
        buttonNew: {
            textDecoration: "none",
            color: "#fff",
            backgroundColor: "#4dc2f1",
            border: 'none',
            width: `${screenSize > 800 ? ('180px') : (<></>)
                }`,
            margin: '30px 0px 0px'
        },
        loader: {
            display: 'flex',
            alignSelf: 'center'
        }
    };

    return (
        <>
            <div style={styles.container}>
                <div style={styles.navbar}>
                    <div style={styles.textsNavbar}>
                        <h3 style={styles.titleNavbar}>Create Asset</h3>
                        <h3 style={styles.subtitleNavbar}>Create a new asset</h3>
                    </div>
                </div>
                <div style={styles.roles}>
                    {loader ? (
                        <div style={styles.containerLoader}>
                            <Loader
                                style={styles.loader}
                                type="TailSpin"
                                color="#4dc2f1"
                                height={100}
                                width={100}
                                timeout={10000} //10 seconds
                            />
                        </div>
                    ) : (
                        <form
                            onSubmit={(e) => handleSubmit(e)}
                            style={styles.form}>
                            <div className="form-group" >
                                <label style={styles.label}>Name</label>
                                <input
                                    onChange={(e) => handleChange(e, 'name')}
                                    name="nameOfRol" type="text" className="form-control" style={styles.input} value={name} placeholder="Name of asset..." required />
                                <label style={styles.label}>Type</label>
                                <div class="form-check" style={styles.checkbox}>
                                    <input class="form-check-input" style={styles.inputCheckbox} name="Hardware" onChange={(e) => handleChange(e, 'type')} type="radio" value="option1" id="flexCheckChecked1" checked={optionSelected === "option1"} />
                                    <label class="form-check-label" style={styles.labelCheckbox} for="flexCheckChecked1">Hardware</label>
                                </div>
                                <div class="form-check" style={styles.checkbox}>
                                    <input class="form-check-input" style={styles.inputCheckbox} name="Software" onChange={(e) => handleChange(e, 'type')} type="radio" value="option2" id="flexCheckChecked2" checked={optionSelected === "option2"} />
                                    <label class="form-check-label" style={styles.labelCheckbox} for="flexCheckChecked2">Software</label>
                                </div>

                                <button type="submit" className="btn" style={styles.buttonNew}>Create asset</button>
                            </div>
                        </form>
                    )}
                </div>
            </div>
        </>
    );
};
