import React, { useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import { useSelector } from "react-redux";

import AuthService from "./services/auth.service";

import Login from "./containers/Login";
import DashboardJML from "./containers/DashboardJML";
import Chat from "./containers/Chat";
import Tasks from "./containers/Tasks";
import Roles from "./containers/Roles";
import Assets from "./containers/Assets";
import SingleRol from "./containers/SingleRol";
import SingleAsset from "./containers/SingleAsset";
import CreateRoles from "./containers/create/Roles";
import CreateAssets from "./containers/create/Assets";

const Main = () => {
  const isAuth = useSelector(state => state.user.isAuth)

  useEffect(() => {
    const user = AuthService.getCurrentUser();

    if (user) {
      //   setCurrentUser(user);
    }
  }, []);

  const connStatus = () => {
    AuthService.connStatus();
  }

  // OJO que sin los [] es bucle infinito
  useEffect(() => { connStatus(); }, []);



  return (
    <>
      {isAuth && isAuth === true ? (
        <Switch>
          <Route exact path="/tasks" component={Tasks} />
          {/* Roles */}
          <Route exact path="/roles/create" component={CreateRoles} />
          <Route exact path="/roles/:idRol" component={SingleRol} />
          <Route exact path="/roles" component={Roles} />

          {/* Assets */}
          <Route exact path="/assets/create" component={CreateAssets} />
          <Route exact path="/assets/:idAsset" component={SingleAsset} />
          <Route exact path="/assets" component={Assets} />

          {/* Generales */}
          <Route path="/bot/:idBot" component={Chat} />
          <Route exact path="/login" component={Login} />
          <Route exact path="/dashboard" component={DashboardJML} />
          <Route exact path="/home" component={DashboardJML} />
          <Route path="/" component={DashboardJML} />
        </Switch>
      ) : (
        <Switch>
          <Route exact path="/login" component={Login} />
          <Route path="/" component={Login} />
        </Switch>
      )}
    </>
  )
}

export default Main;