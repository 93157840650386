import firebase from 'firebase';

var firebaseConfig = {
    apiKey: "AIzaSyCEdpJJOpUKTQwerdTJJjTfgsaR33dS7qg",
    authDomain: "test-bf2a6.firebaseapp.com",
    projectId: "test-bf2a6",
    storageBucket: "test-bf2a6.appspot.com",
    messagingSenderId: "154863377581",
    appId: "1:154863377581:web:dd3313f4d24ae2f415ba24",
    measurementId: "G-ZTCH51J39K"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
//firebase.analytics();

export default firebase;