import React from "react";
import { Link } from "react-router-dom";


export default ({ screenSize, darkModeState, handleSubmit, handleDarkMode }) => {
    const styles = {
        backColor: {
            position: "fixed",
            left: 0,
            top: 0,
            right: 0,
            bottom: 0,
            height: "100%",
            backgroundColor: `${darkModeState ? ("hsl(230, 17%, 14%)") : ("hsl(0, 0%, 100%)")}`
        },
        container: {
            position: "fixed",
            bottom: 0,
            top: 0,
            left: 0,
            right: 0,
            display: "flex",
            flexDirection: "column",
            //   padding: `${screenSize > 800 ? ("3% 2% 3% 20%") : ("30px")}`,
            padding: `${screenSize > 800 ? ("3% 2% 3% 280px") : ("30px")}`,
            height: "100%",
            overflow: `auto`,
        },
        navbar: {
            display: "flex",
            flex: 1.5,
            margin: `${screenSize > 800 ? ("0px 20px") : ("0px")}`,
            marginBottom: `${screenSize > 800 ? ("0px") : ("30px")}`,
        },
        textsNavbar: {
            display: "flex",
            flex: 8,
            flexDirection: "column",
            // justifyContent: 'center'
        },
        titleNavbar: {
            marginBottom: `${screenSize > 800 ? ('5px') : ("10px")}`,
            fontSize: "28px",
            color: `${darkModeState ? ("hsl(0, 0%, 100%)") : ("hsl(230, 17%, 14%)")}`,
            fontFamily: "InterBold",
            lineHeight: '1.4',
            //  margin: '0px'
        },
        subtitleNavbar: {
            margin: 0,
            fontSize: "14px",
            color: `${darkModeState ? ("hsl(228, 34%, 66%)") : ("hsl(228, 12%, 44%)")}`,
            fontFamily: "InterBold",
        },
        toogleNavbar: {
            display: "flex",
            flex: 4,
            alignItems: "center",
            justifyContent: `${screenSize > 800 ? ("flex-end") : ("center")}`,
            flexDirection: `${screenSize > 800 ? ("row") : ("column")}`,
        },
        textToogle: {
            fontSize: "14px",
            fontFamily: "InterBold",
            alignSelf: "center",
            textDecoration: "none",
            color: 'inherit',
            border: 'none',
            outline: 'none',
            padding: '12px',
            backgroundColor: `transparent`
        },
        textUser: {
            fontSize: "14px",
            fontFamily: "InterBold",
            alignSelf: "center",
            textDecoration: "none",
            color: 'inherit',
            border: 'none',
            outline: 'none',
            padding: 0,
            backgroundColor: `transparent`
        },
        roles: {
            display: "flex",
            flex: 11,
            flexDirection: "column",
            alignItems: 'center',
            margin: `${screenSize > 800 ? ("0px 20px") : ("0px")}`,
            //  justifyContent: 'space-between',
            //   margin: `${screenSize > 800 ? ('20px 0px') : ("0px 0px 30px")}`,
        },
        form: {
            width: '100%',
            // margin: '20px 0px'
        },
        label: {
            margin: '0px 0px 15px',
            fontSize: "18px",
            fontFamily: "InterBold",
            color: `${darkModeState ? ("hsl(228, 34%, 66%)") : ("hsl(228, 12%, 44%)")} `,
        },
        input: {
            fontFamily: "InterRegular",
            fontSize: "16px",
            color: `${darkModeState ? ("hsl(228, 34%, 66%)") : ("hsl(228, 12%, 44%)")} `,
            margin: '0px 0px 25px',
        },
        checkbox: {
            display: 'flex',
            alignItems: 'center',
            margin: '0px 0px 10px 3px'
        },
        labelCheckbox: {
            fontFamily: "InterRegular",
            fontSize: "16px",
            color: `${darkModeState ? ("hsl(228, 34%, 66%)") : ("hsl(228, 12%, 44%)")} `,
        },
        inputCheckbox: {
            fontFamily: "InterRegular",
            fontSize: "16px",
            border: '1px solid red',
        },
        buttonNew: {
            textDecoration: "none",
            color: "#fff",
            backgroundColor: "#4dc2f1",
            border: 'none',
            width: `${screenSize > 800 ? ('180px') : (<></>)
                }`,
            margin: '30px 0px 0px'
        },
    };

    return (
        <>
            <div style={styles.backColor} />
            <div style={styles.container}>
                <div style={styles.navbar}>
                    <div style={styles.textsNavbar}>
                        <h3 style={styles.titleNavbar}>Create Rol</h3>
                        <h3 style={styles.subtitleNavbar}>Create a new rol</h3>
                    </div>
                </div>
                <div style={styles.roles}>
                    <form
                        onSubmit={(e) => handleSubmit(e)}
                        style={styles.form}>
                        <div className="form-group" >
                            <label style={styles.label}>Name of rol</label>
                            <input
                                //                onChange={(e) => input(e)}
                                name="nameOfRol" type="text" className="form-control" style={styles.input} placeholder="Name of rol..." required />
                            <label style={styles.label}>Assets</label>
                            <div class="form-check" style={styles.checkbox}>
                                <input class="form-check-input" style={styles.inputCheckbox} type="checkbox" value="" id="flexCheckChecked1" />
                                <label class="form-check-label" style={styles.labelCheckbox} for="flexCheckChecked1">Credit Card</label>
                            </div>
                            <div class="form-check" style={styles.checkbox}>
                                <input class="form-check-input" style={styles.inputCheckbox} type="checkbox" value="" id="flexCheckChecked2" />
                                <label class="form-check-label" style={styles.labelCheckbox} for="flexCheckChecked2">Car</label>
                            </div>
                            <div class="form-check" style={styles.checkbox}>
                                <input class="form-check-input" style={styles.inputCheckbox} type="checkbox" value="" id="flexCheckChecked3" />
                                <label class="form-check-label" style={styles.labelCheckbox} for="flexCheckChecked3">Computer</label>
                            </div>
                            <div class="form-check" style={styles.checkbox}>
                                <input class="form-check-input" style={styles.inputCheckbox} type="checkbox" value="" id="flexCheckChecked4" />
                                <label class="form-check-label" style={styles.labelCheckbox} for="flexCheckChecked4">Access Point</label>
                            </div>
                            <div class="form-check" style={styles.checkbox}>
                                <input class="form-check-input" style={styles.inputCheckbox} type="checkbox" value="" id="flexCheckChecked5" />
                                <label class="form-check-label" style={styles.labelCheckbox} for="flexCheckChecked5">Cellphone</label>
                            </div>
                            <div class="form-check" style={styles.checkbox}>
                                <input class="form-check-input" style={styles.inputCheckbox} type="checkbox" value="" id="flexCheckChecked6" />
                                <label class="form-check-label" style={styles.labelCheckbox} for="flexCheckChecked6">Tablet</label>
                            </div>
                            <div class="form-check" style={styles.checkbox}>
                                <input class="form-check-input" style={styles.inputCheckbox} type="checkbox" value="" id="flexCheckChecked7" />
                                <label class="form-check-label" style={styles.labelCheckbox} for="flexCheckChecked7">Extra Monitor</label>
                            </div>
                            <div class="form-check" style={styles.checkbox}>
                                <input class="form-check-input" style={styles.inputCheckbox} type="checkbox" value="" id="flexCheckChecked8" />
                                <label class="form-check-label" style={styles.labelCheckbox} for="flexCheckChecked8">Ip Camera</label>
                            </div>
                            <button type="submit" className="btn" style={styles.buttonNew}>Create rol</button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
};
